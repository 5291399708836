import React, { useMemo } from 'react';
import { find } from 'lodash';
import ToolTip from './Tooltip';
import { langOptions, locationOptions, Option } from '../types';
import { useTranslation } from '../i18n';

const getFlagShortKey = (name: string) => {
  switch (name) {
    case 'dk':
    case 'da':
      return 'dk';
    case 'en':
    case 'gb':
    case 'uk':
      return 'gb';
    case 'nb':
      return 'no';
    default:
      return name;
  }
};

const FlagIcon = ({
  flag = 'de',
  height = '17px',
  width = '22px',
  toolTipText = '',
  lowQuality = false,
  styleObject = {},
  disabled = true,
  useLocation = false,
  ...props
}) => {
  const { t } = useTranslation('selects');
  const text = useMemo(() => {
    const val = find<Option>(
      useLocation ? locationOptions : langOptions,
      ({ value }) => value === toolTipText
    )?.label;
    return t(val || '');
  }, [flag, langOptions, locationOptions, useLocation]);
  return (
    <ToolTip disabled={disabled} text={text}>
      <img
        {...props}
        style={{ ...styleObject, objectFit: 'cover' }}
        height={height}
        width={width}
        src={`https://s.svgbox.net/flags-${lowQuality ? 'ld' : 'hd'
          }.svg?ic=${getFlagShortKey(flag?.toLowerCase())}&fill=000`}
        alt={flag}
      />
    </ToolTip>
  );
};

export default FlagIcon;
