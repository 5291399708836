import React, { FunctionComponent, useState, useRef, useEffect, ReactNode } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import StyleGuide from './StyleGuide';

const useResize = (myRef: any) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setWidth(myRef.current.offsetWidth);
    };

    myRef.current.addEventListener('resize', handleResize);

    return () => {
      myRef.current.removeEventListener('resize', handleResize);
    };
  }, [myRef.current]);

  return { width };
};

const Container = styled.div`
  position: relative;
  display: flex;
  z-index: 9;
`;
const TooltipContainer = styled(animated.div) <{ marginleft?: number }>`
  position: absolute;
  background: ${StyleGuide.colors.tooltipBackground};
  padding: 4px;
  left: 50%;
  border-radius: 3px;
  z-index: 1;
`;
const TooltipText = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: white;
  white-space: nowrap;
`;

type TooltipProps = {
  text: string;
  toLeft?: boolean;
  toRight?: boolean;
  withoutSymbol?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  customInput?: ReactNode;
  toBottom?: boolean;
  containerStyle?: {};
  contentStyle?: {};
};

const getTranslateDirection = (show: boolean, toBottom: boolean): string => {
  if (show && toBottom) return '-25px';
  if (show) return '25px';
  return '0px';
};

const Tooltip: FunctionComponent<TooltipProps> = ({
  disabled = false,
  children,
  text = 'Tooltip',
  toLeft = false,
  toRight = false,
  withoutSymbol = false,
  toBottom = false,
  onClick = () => { },
  customInput,
  containerStyle,
  contentStyle
}) => {
  const [show, setShow] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (ref && ref.current !== null && ref.current.offsetWidth) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  const springAnimation = useSpring({
    opacity: show ? 1 : 0,
    bottom: getTranslateDirection(show, toBottom),
    transform: show
      ? `scale(1) translateX(${toLeft ? '-100%' : toRight ? '0%' : '-50%'}) translateY(${toBottom ? '50%' : '0%'})`
      : 'scale(0) translateX(-50%) translateY(0%)'
  });

  return (
    <Container
      style={containerStyle}
      onMouseEnter={disabled ? undefined : () => setShow(true)}
      onMouseLeave={disabled ? undefined : () => setShow(false)}
      onClick={disabled ? undefined : onClick}
    >
      <TooltipContainer
        style={{ ...springAnimation, ...contentStyle }}
        ref={ref}
        marginleft={-(width / 2)}
      >
        {customInput || (<TooltipText>{text}</TooltipText>)}
      </TooltipContainer>
      {children}
    </Container>
  );
};

export default Tooltip;
