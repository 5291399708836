import React, { FunctionComponent, ChangeEvent, useRef } from 'react';
import styled from 'styled-components';
import Icon from './Icon';
import StyleGuide from './StyleGuide';

interface InputTextWithIconProps {
  iconName: string;
  error: boolean;
  value: string;
  placeholder: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  type?: string;
}
interface InputProps {
  value: string;
  placeholder: string;
  onChange: Function;
}

const Input = styled.input`
  outline: inherit;
  background: none;
  color: inherit;
  border: none;
  width: 100%;
  font: inherit;
  color: ${StyleGuide.colors.fontOnPrimary};
  margin: 0px;
  ::placeholder {
    color: ${StyleGuide.colors.fontOnPrimary};
    font-weight: 100;
    letter-spacing: 5px;
    font-size: 0.7em;
    opacity: 0.9;
  }
`;

interface RoundButtonProps {
  error: boolean;
}
const RoundedButton = styled.div<RoundButtonProps>`
  background-color: ${StyleGuide.colors.loginInput};
  flex-direction: row;
  height: 50px;
  border-radius: 25px;
  display: flex;
  margin: 5px 0px;
  padding: 0px 15px;
  align-items: center;
  border: 2px solid yellow;
  border-width: 2px;
  border-color: ${({ error }) =>
    error ? StyleGuide.colors.loginError : 'transparent'};
  :focus-within {
    border-color: ${StyleGuide.colors.primary};
  }
`;

const InputTextWithIcon: FunctionComponent<InputTextWithIconProps> = ({
  iconName,
  error,
  ...props
}) => {
  const ref = useRef<HTMLInputElement>(null);
  return (
    <RoundedButton
      error={error}
      onClick={() => {
        if (ref.current) {
          ref.current.focus();
        }
      }}
    >
      <Icon name={iconName} color={StyleGuide.colors.primary} size="2em" />
      <Input ref={ref} {...props} />
    </RoundedButton>
  );
};

export default InputTextWithIcon;
