import React from 'react';
import styled from 'styled-components';
import StyleGuide from './StyleGuide';
import Modal from './Modal';
import { ModalText } from './ThemedComponents';

const ModalBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  background: var(--color-surface);
  /* overflow: hidden; */
`;

const ModalHeader = styled.div`
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  ${StyleGuide.shadowHeader};
  padding: 5px 20px;
  margin-bottom: 10px;
  color: var(--color-fontOnBackground);
`;

const ModalContent = styled.div<{ padding?: string }>`
  display: flex;
  flex: 1;
  max-height: 60vh;
  overflow: visible;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0px;
  }
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: ${({ padding }) => padding || '5px 20px'};
`;

const ModalFooter = styled.div<{ hasLeftButton?: boolean }>`
  display: flex;
  justify-content: ${({ hasLeftButton }) =>
    hasLeftButton ? 'space-between' : 'flex-end'};
  align-items: center;
  padding: 5px 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const getColorForButton = ([focusColor, activeColor, inactiveColor]: [
  string,
  string,
  string
]) => ({
  isActive,
  isFocused
}: {
  isActive?: boolean;
  isFocused?: boolean;
}) => {
    if (isFocused && isActive) {
      return focusColor;
    }
    if (isActive) {
      return activeColor;
    }
    return inactiveColor;
  };

const Button = styled.div<{ isActive?: boolean; isFocused?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 150px;
  height: 25px;
  margin: 5px;
  padding: 10px 5px;
  cursor: ${({ isActive }) => (isActive ? 'pointer' : '')};
  background: ${({ ...props }) =>
    getColorForButton([
      StyleGuide.colors.primary,
      'var(--color-surface)',
      'var(--color-modalBGButtonInActive)'
    ])(props)};
  color: ${({ ...props }) =>
    getColorForButton([
      StyleGuide.colors.fontOnPrimary,
      StyleGuide.colors.primary,
      'var(--color-modalFontButtonInActive)'
    ])(props)};
`;

const ModalTitle = styled.h4`
  font-size: 1em;
`;

type Button = {
  text: string;
  isActive?: boolean;
  isFocused?: boolean;
  action: () => void;
};

type CreateModalProps = {
  isVisible: boolean;
  onRequestClose: () => void;
  title?: string;
  buttons?: Button[];
  renderHeaderInfo?: () => React.ReactNode;
  renderError?: () => React.ReactNode;
  className?: string;
  contentPadding?: string;
  withoutHeader?: boolean;
  leftButton?: Button;
  customButton?: React.ReactNode;
};

const ModalBlank: React.FC<CreateModalProps> = ({
  isVisible = false,
  onRequestClose,
  title = 'Title',
  children,
  renderHeaderInfo,
  renderError,
  className,
  withoutHeader = false,
  contentPadding,
  leftButton,
  customButton,
  buttons = [
    {
      text: 'Abbrechen',
      isActive: true,
      isFocused: false,
      action: onRequestClose
    },
    {
      text: 'Bestätigen',
      isActive: false,
      isFocused: false,
      action: onRequestClose
    }
  ]
}) => {
  return (
    <Modal isVisible={isVisible}>
      <ModalBox className={className}>
        {!withoutHeader && (
          <ModalHeader>
            <ModalText bold>{title}</ModalText>
            {renderHeaderInfo && renderHeaderInfo()}
          </ModalHeader>
        )}
        <ModalContent padding={contentPadding}>{children}</ModalContent>
        <ModalFooter hasLeftButton={!!leftButton}>
          {customButton}
          {leftButton && (
            <Button
              key={leftButton.text}
              isActive={leftButton.isActive}
              isFocused={leftButton.isFocused}
              onClick={leftButton.isActive ? leftButton.action : undefined}
              style={{
                background: leftButton?.isActive
                  ? StyleGuide.colors.loginError
                  : StyleGuide.colors.modalBGButtonInActive
              }}
            >
              <ModalText>{leftButton.text.toUpperCase()}</ModalText>
            </Button>
          )}
          {renderError && renderError()}
          <ButtonContainer>
            {buttons.map(item => (
              <Button
                key={item.text}
                isActive={item.isActive}
                isFocused={item.isFocused}
                onClick={item.isActive ? item.action : undefined}
              >
                <ModalText>{item.text.toUpperCase()}</ModalText>
              </Button>
            ))}
          </ButtonContainer>
        </ModalFooter>
      </ModalBox>
    </Modal>
  );
};

export default ModalBlank;
