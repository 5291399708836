import React from 'react';
import { createPortal } from 'react-dom';

const Overlay: React.FC<{}> = ({ children }) => {
  const element = document.getElementById('portal');
  if (!element) {
    return null;
  }
  return createPortal(children, element);
};

export default Overlay;
