import React, { FunctionComponent } from 'react';
import styled, { keyframes } from 'styled-components';

const LoadBackground = styled.div<{ notFixed?: boolean }>`
  width: 100%;
  height: 100%;
  position: ${({ notFixed }) => (notFixed ? 'fixed' : 'fixed')};
  background-color: ${({ notFixed }) =>
    notFixed ? 'transparent' : 'rgba(0, 0, 0, 0.5)'};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  pointer-events: ${({ notFixed }) => (notFixed ? 'none' : 'auto')};;
`;

const Center = styled.div`
  position: relative;
  left: 50%;
  top: 50%;
  width: 10px;
  height: 10px;
  transform: translate(-50%, -50%);
`;

const CircleAnimation = keyframes`
0%, 20%, 80%, 100% {
    transform: scale(1);
    
    opacity:0;
  }
  50% {
    transform: scale(1.5);
    opacity:1;
  }
`;
interface CircleProps {
  top: number;
  left: number;
  delay: number;
  notFixed?: boolean;
}

const Circle = styled.div<CircleProps>`
  position: absolute;
  width: 15px;
  height: 15px;
  background: ${({ notFixed}) => (notFixed ? 'var(--color-loader)' : '#fff')};
  border-radius: 50%;
  animation: ${CircleAnimation} 0.8s linear infinite;
  animation-delay: ${props => `${props.delay}s` || ''};
  top: ${props => `${props.top}px` || 'px'};
  left: ${props => `${props.left}px` || 'px'};
`;
/**
 * Big Loader in Circle Design
 */
const LoadingScreen: FunctionComponent<{ notFixed?: boolean }> = ({
  notFixed = true,
  ...props
}) => {
  return (
    <LoadBackground notFixed={notFixed}
    >
      <Center>
        <Circle notFixed={notFixed} delay={0.1} top={-50} left={0} />
        <Circle notFixed={notFixed} delay={0.2} top={-35} left={35} />
        <Circle notFixed={notFixed} delay={0.3} top={0} left={50} />
        <Circle notFixed={notFixed} delay={0.4} top={35} left={35} />
        <Circle notFixed={notFixed} delay={0.5} top={50} left={0} />
        <Circle notFixed={notFixed} delay={0.6} top={35} left={-35} />
        <Circle notFixed={notFixed} delay={0.7} top={0} left={-50} />
        <Circle notFixed={notFixed} delay={0.8} top={-35} left={-35} />
      </Center>
    </LoadBackground>
  );
};

export default LoadingScreen;
