import React, {
  FunctionComponent,
  useMemo,
  useState,
  useEffect,
  useRef
} from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { useTranslation, i18n } from '../i18n';
import FlagIcon from './FlagIcon';
import StyleGuide from './StyleGuide';

const Container = styled.div<{ isOpen: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  margin: 2px;
  cursor: ${({ isOpen }) => (isOpen ? 'initial' : 'pointer')};
  background: 'lightgrey';
`;

const FlagsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  width: min-content;
`;

const FlagContainer = styled(animated.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  margin: 2px 3px;
`;

const Spinner = styled(animated.div)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled(animated.h1)`
  font-size: 0.8em;
  text-align: center;
  font-weight: 400;
  margin: 5px;
  color: ${StyleGuide.colors.fontOnHeader};
  text-transform: uppercase;
`;

const getLangKey = (lang: string): string => {
  switch (lang.slice(0, 2)) {
    case 'de':
      return 'de';
    default:
      return 'gb';
  }
};

const languages = ['de', 'gb'];

const LanguageComponent: FunctionComponent = () => {
  const { t } = useTranslation('header');
  const [open, setOpen] = useState(false);
  // @ts-ignore
  const { opacity, width, transform } = useSpring({
    to: {
      width: open ? '50px' : '22px',
      opacity: open ? 1 : 0,
      transform: `rotate(${open ? '-720' : '0'}deg)`
    }
  });

  const [currentLang, setCurrentLang] = useState('gb');

  useEffect(() => {
    if (i18n?.language) setCurrentLang(getLangKey(i18n.language));
  }, [i18n?.language]);

  const langs = useMemo(
    () => (open ? languages : languages.filter(item => item === currentLang)),
    [open, currentLang]
  );

  const closeContainer = () => {
    if (open) setOpen(false);
  };

  useEffect(() => {
    document?.addEventListener('click', closeContainer);

    return () => document?.removeEventListener('click', closeContainer);
  });

  return (
    <Container onClick={() => setOpen(!open)} isOpen={open}>
      <FlagsContainer>
        {langs.map((item: string, index: number) => (
          <FlagContainer
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            style={{ width }}
            onClick={
              open
                ? () => {
                    setOpen(false);
                    i18n.changeLanguage(item === 'gb' ? 'en' : item);
                  }
                : undefined
            }
          >
            <Spinner style={{ transform }}>
              <FlagIcon
                flag={item}
                toolTipText={item}
                width="22px"
                height="22px"
                styleObject={{ borderRadius: '50%', objectFit: 'cover' }}
              />
            </Spinner>
            {/* <Text style={{ opacity }}>{t(`headerLang.${item}`)}</Text> */}
          </FlagContainer>
        ))}
      </FlagsContainer>
    </Container>
  );
};

export default LanguageComponent;
