import React, {
  memo,
  useState,
  useEffect,
  useMemo,
  useRef,
  FunctionComponent
} from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import StyleGuide from './StyleGuide';
import Icon from './Icon';
import Overlay from './Overlay';

const Content = styled.div`
  display: flex;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  min-height: 120px;
  min-width: 200px;
  padding: 10px;
  z-index: 1234;
`;

const ContentText = styled.p`
  color: ${StyleGuide.colors.fontOnPrimary};
`;

type ModalProps = {
  iconName?: string;
  image?: string;
  trigger?: boolean;
  text?: string;
};

const ConfirmModal: FunctionComponent<ModalProps> = ({
  iconName = 'close',
  text = 'Aktion Erfolgreich',
  image,
  trigger = false
}) => {
  const [show, setShow] = useState(false);
  const prevTrigger = useRef(trigger);
  const props = useSpring({
    // @ts-ignore
    to: [{ opacity: show ? 0.9 : 0 }, { opacity: 0 }],
    from: {
      opacity: 0
    },
    config: { tension: '100' }
  });

  useEffect(() => {
    let timeoutId: number | null = null;
    function hide() {
      setShow(false);
    }
    if (!trigger && prevTrigger.current) {
      setShow(true);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(hide, 3000);
    }
    prevTrigger.current = trigger;
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      hide();
    };
  }, [trigger]);

  if (!show) {
    return null;
  }

  return (
    <Overlay>
      <Content as={animated.div} style={props}>
        {image && <img src={image} width="50px" height="50px" alt="pic" />}
        {iconName && (
          <Icon name={iconName} size="60px" color="white" margin="5px" />
        )}
        <ContentText>{text}</ContentText>
      </Content>
    </Overlay>
  );
};

export default memo(ConfirmModal);
