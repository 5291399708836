import { css } from 'styled-components';

const boxShadowHigh = css`
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
`;

const boxShadowLow = css`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
`;

const shadowHeader = css`
  box-shadow: 0 2px 4px 0 rgba(108, 182, 182, 0.5);
`;

const backgroundGradient = css`
  background: linear-gradient(135deg, #956d58, #be917a 50%, #956d58);
`;

const buttonGradient = css`
  background: linear-gradient(to right, #7ac8f7, #cce2f0 40%, #7ac8f7);
`;

const surfaceGradient = css`
  background-image: linear-gradient(#f2f0f0, #ffffff);
`;

export default {
  colors: {
    header: '#FFFFFF',
    fontOnHeader: '#000000',
    fontOnHeaderActive: '#6CB6B6',
    fontOnHeaderHover: '#6CB6B6',

    background: '#F0F0F0',

    surface: '#FFFFFF',
    fontOnSurface: '#000000',
    // Tables
    tableRow: '#FFFFFF',
    tableRowOdd: '#F9F9F9',
    tableRowError: '#FFBABA',
    tableRowHover: '#DDDDDD',
    fontOnTableRow: '#606266',
    fontOnTableRowError: '#D8000C',
    tableInfoBubble: '#B0B0B0',
    tableToolTip: '#A0A0A0',
    // Login
    loginBackground: '#333333',
    loginInput: '#222222',
    loginError: '#D0021B',
    // General
    primary: '#6CB6B6',
    fontOnPrimary: '#FFFFFF',
    tooltipBackground: '#6D6D6D',
    error: '#FFBABA',
    fontOnError: '#D8000C',
    // Modal
    modalBackground: 'rgba(0,0,0,0.6)',
    modalFont: '#000000',
    modalFontButton: '#6CB6B6',
    modalFontButtonInActive: '#FFFFFF',
    modalBGButton: '#FFFFFF',
    modalBGButtonInActive: '#D3D3D3',
    // Input
    inputBorder: '#e6e6e6',
    inputPlaceHolder: '#b0b0b0',
    dragOverlay: 'rgba(108, 182, 182,0.2)',
    inputError: '#D0021B',
    inputCorrect: 'green',
    // StatusColors
    statusOnline: '#7ED321',
    statusInActive: '#F5A623',
    statusOffline: '#D0021B',
    online: '#7ED321',
    off: '#D0021B',
    // LoadingImageColors
    loaderLight: '#FFFFFF',
    loaderDark: '#F5F5F5'
  },

  shadows: {
    high: '0px 2px 4px 0px rgba(0, 0, 0, 0.5)',
    none: '0px 0px 0px 0px rgba(0, 0, 0, 0.5)',
    select: '0 1px 15px 1px rgba(69,65,78,0.10)'
  },
  boxShadowLow,
  boxShadowHigh,
  backgroundGradient,
  buttonGradient,
  surfaceGradient,
  shadowHeader
};
