import React, { useState, FunctionComponent } from 'react';
import styled, { keyframes } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import StyleGuide from './StyleGuide';
import Image from 'next/image';

const LoadingAnimation = keyframes`
0% {
    background-color: var(--color-loaderDark);
  }
  50% {
    background-color: var(--color-loaderLight);
  }
  100% {
    background-color: var(--color-loaderDark);
  }
`;

const Container = styled.div<{
  fullWidth?: boolean;
  isLoading?: boolean;
  borderRadius?: string;
  width?: string | number | undefined;
  height?: string | number | undefined;
}>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: ${({ height }) => height || 'min-content'};
  width: ${({ width }) => width || 'min-content'};
  border-radius: ${({ borderRadius }) => borderRadius || '0px'};
  ${({ isLoading }) => (isLoading ? undefined : StyleGuide.boxShadowHigh)};
  :hover {
    opacity: ${({ onClick }) => (onClick ? 0.8 : 1)};
  }
`;

const LoadingIndicator = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background-color: #f5f5f5;
  animation: ${LoadingAnimation} 2.5s infinite ease-in-out;
`;

const UImage = styled(Image)`
  object-fit: cover;
`;

type ImageProps = {
  onClick?: () => void;
  fullWidth?: boolean;
  boxStyle?: {};
  borderRadius?: string;
  src: string;
  height: string | number | undefined;
  width: string | number | undefined;
};

const LoadingImage: FunctionComponent<ImageProps> = ({
  onClick,
  fullWidth,
  boxStyle,
  borderRadius,
  width,
  height,
  src,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const Animation = useSpring({ to: { opacity: isLoading ? 0 : 1 } });
  return (
    <Container
      style={{ ...boxStyle, cursor: onClick ? 'pointer' : 'inherit' }}
      onClick={onClick ? () => onClick() : undefined}
      isLoading={isLoading}
      fullWidth={fullWidth}
      borderRadius={borderRadius}
      width={width}
      height={height}
    >
      <UImage
        style={Animation}
        onLoad={() => setIsLoading(false)}
        alt="pic"
        width={width}
        height={height}
        src={src}
        {...props}
      />
      {isLoading && <LoadingIndicator />}
    </Container>
  );
};

export default LoadingImage;
