import React, { memo, FunctionComponent } from 'react';
import styled from 'styled-components';

interface IconProps {
  name: string;
  size?: string;
  margin?: string;
  color?: string;
}

const StyledIcon = styled.span.attrs<IconProps>(props => ({
  className: `icon-${props.name}`
}))<IconProps>`
  font-size: ${props => props.size || 'inherit'};
  color: ${props => props.color || 'inherit'};
  margin: ${props => props.margin || '0'};
`;

const Icon: FunctionComponent<IconProps> = props => <StyledIcon {...props} />;

export default memo(Icon);
