import React, { useState, FunctionComponent } from 'react';
import { animated, useSprings } from 'react-spring';
import styled from 'styled-components';

function getColorFromPosition(index: number) {
  if (index === 1) return 'black';

  if (index === 2) return 'dimgrey';

  return 'lightgrey';
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  padding: 5px;
`;

const Dot = styled(animated.div)<{ index: number }>`
  height: 15px;
  width: 15px;
  border-radius: 10px;
  margin: 10px 5px;
  background-color: ${({ index }) => getColorFromPosition(index)};
`;
/**
 * Small Pagination Loader in Point Design
 */
const PaginationLoader: FunctionComponent<{ dotCount?: number }> = ({
  dotCount = 3
}) => {
  const [play, setPlay] = useState(false);
  const trail = useSprings(
    dotCount,
    Array.from({ length: dotCount }, (_item, index) => ({
      from: { transform: `translate3d(0px, 0%, 0px)`, opacity: 1 },
      to: [
        { transform: `translate3d(0px, -150%, 0px)`, opacity: 0 },
        { transform: `translate3d(0px, 0%, 0px)`, opacity: 1 }
      ],
      delay: index * 300,
      reset: !play,
      onStart: index === 0 ? () => setPlay(true) : undefined,
      onRest: index === dotCount - 1 ? () => setPlay(false) : undefined
    }))
  );
  return (
    <Container>
      {trail.map((style, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Dot key={index} style={style} index={index + 1} />
      ))}
    </Container>
  );
};

export default PaginationLoader;
