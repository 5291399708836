import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import Overlay from './Overlay';
import { motion, AnimatePresence } from 'framer-motion';

const DimContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  background-color:rgba(0, 0, 0, 0.6);
  z-index: 999;
  display: flex;
`;

const ModalContent = styled(motion.div) <{
  $isFilled?: number;
}>`
  // display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  max-width: 90%;
  width: ${props => (props.$isFilled ? '100%' : '')};
  height: ${props => (props.$isFilled ? '100%' : '')};
  border-radius: ${props => (props.$isFilled ? '0px' : '5px')};
`;

type ModalProps = {
  isVisible: boolean;
  title?: string;
  children: React.ReactNode;
  isFilled?: number;
};

const Modal: FunctionComponent<ModalProps> = ({
  isVisible = false,
  children,
  isFilled = false
}) => {

  // SSR seems to be shitty sometimes.
  if (typeof window === 'undefined')
    return null;

  return (
    <Overlay>
      <AnimatePresence>
        {isVisible &&
          <DimContainer
            initial={{
              opacity: 0
            }}
            animate={{
              opacity: 1
            }}
            exit={{
              opacity: 0
            }}
          >
            <ModalContent
              initial={{
                y: -100,
                opacity: 0
              }}
              animate={{
                y: 0,
                opacity: 1
              }}
              exit={{
                y: -100,
                opacity: 0
              }}
              transition={{
                type: "tween",
                delay: isVisible ? 0.4 : 0
              }}
              $isFilled={isFilled ? 1 : 0}
            >
              {children}
            </ModalContent>

          </DimContainer>
        }
      </AnimatePresence>
    </Overlay>
  );
};

export default Modal;
