import { slice, findIndex, indexOf } from 'lodash';
import { Legitimation, Gender, LocationShort } from './enums';

export type Option = {
  label: string;
  value: string;
};

export const chatLangs = [
  { value: LocationShort.German, label: 'languages.de' },
  { value: LocationShort.English, label: 'languages.uk' },
  { value: LocationShort.Dansk, label: 'languages.da' },
  { value: LocationShort.Australia, label: 'languages.au' },
  { value: LocationShort.Spain, label: 'languages.es' },
  { value: LocationShort.Portugues, label: 'languages.pt' },
  { value: LocationShort.Norway, label: 'languages.no' },
  { value: LocationShort.Finland, label: 'languages.fi' },
  { value: LocationShort.Italian, label: 'languages.it' },
  { value: LocationShort.Polen, label: 'languages.pl' },
  { value: LocationShort.New_Zealand, label: 'languages.nz' },
];

export const langOptions = [
  { value: LocationShort.German, label: 'languages.de' },
  { value: LocationShort.English, label: 'languages.uk' },
  { value: LocationShort.Dansk, label: 'languages.da' },
  { value: LocationShort.Australia, label: 'languages.au' },
  { value: LocationShort.Spain, label: 'languages.es' },
  { value: LocationShort.Portugues, label: 'languages.pt' },
  { value: LocationShort.Norway, label: 'languages.no' },
  { value: LocationShort.Argentinia, label: 'languages.ar' },
  { value: LocationShort.Belgian, label: 'languages.be' },
  { value: LocationShort.Brazil, label: 'languages.br' },
  { value: LocationShort.Chile, label: 'languages.cl' },
  { value: LocationShort.CostaRica, label: 'languages.cr' },
  { value: LocationShort.Ecuador, label: 'languages.ec' },
  { value: LocationShort.Finland, label: 'languages.fi' },
  { value: LocationShort.France, label: 'languages.fr' },
  { value: LocationShort.Ireland, label: 'languages.ie' },
  { value: LocationShort.Italian, label: 'languages.it' },
  { value: LocationShort.Kolumbien, label: 'languages.co' },
  { value: LocationShort.Liechtenstein, label: 'languages.li' },
  { value: LocationShort.Luxemburg, label: 'languages.lu' },
  { value: LocationShort.Mexiko, label: 'languages.mx' },
  { value: LocationShort.Niederlande, label: 'languages.nl' },
  { value: LocationShort.New_Zealand, label: 'languages.nz' },
  { value: LocationShort.Österreich, label: 'languages.at' },
  { value: LocationShort.Paraguay, label: 'languages.py' },
  { value: LocationShort.Peru, label: 'languages.pe' },
  { value: LocationShort.Polen, label: 'languages.pl' },
  { value: LocationShort.Schweden, label: 'languages.se' },
  { value: LocationShort.Schweiz, label: 'languages.ch' },
  { value: LocationShort.Türkei, label: 'languages.tr' },
  { value: LocationShort.Uruguay, label: 'languages.uy' },
  { value: LocationShort.Venezuela, label: 'languages.ve' }
];

export const nationalityOptions = [
  { value: LocationShort.German, label: 'languages.de' },
  { value: LocationShort.English, label: 'languages.uk' },
  { value: LocationShort.Dansk, label: 'languages.da' },
  { value: LocationShort.Australia, label: 'languages.au' },
  { value: LocationShort.Spain, label: 'languages.es' },
  { value: LocationShort.Portugues, label: 'languages.pt' },
  { value: LocationShort.Norway, label: 'languages.no' },
  { value: LocationShort.Argentinia, label: 'languages.ar' },
  { value: LocationShort.Belgian, label: 'languages.be' },
  { value: LocationShort.Brazil, label: 'languages.br' },
  { value: LocationShort.Chile, label: 'languages.cl' },
  { value: LocationShort.CostaRica, label: 'languages.cr' },
  { value: LocationShort.Ecuador, label: 'languages.ec' },
  { value: LocationShort.Finland, label: 'languages.fi' },
  { value: LocationShort.France, label: 'languages.fr' },
  { value: LocationShort.Ireland, label: 'languages.ie' },
  { value: LocationShort.Italian, label: 'languages.it' },
  { value: LocationShort.Kolumbien, label: 'languages.co' },
  { value: LocationShort.Liechtenstein, label: 'languages.li' },
  { value: LocationShort.Luxemburg, label: 'languages.lu' },
  { value: LocationShort.Mexiko, label: 'languages.mx' },
  { value: LocationShort.Niederlande, label: 'languages.nl' },
  { value: LocationShort.New_Zealand, label: 'languages.nz' },
  { value: LocationShort.Österreich, label: 'languages.at' },
  { value: LocationShort.Paraguay, label: 'languages.py' },
  { value: LocationShort.Peru, label: 'languages.pe' },
  { value: LocationShort.Polen, label: 'languages.pl' },
  { value: LocationShort.Schweden, label: 'languages.se' },
  { value: LocationShort.Schweiz, label: 'languages.ch' },
  { value: LocationShort.Türkei, label: 'languages.tr' },
  { value: LocationShort.Uruguay, label: 'languages.uy' },
  { value: LocationShort.Venezuela, label: 'languages.ve' }
];
export const locationOptions = [
  { value: LocationShort.German, label: 'countries.de' },
  { value: LocationShort.English, label: 'countries.uk' },
  { value: LocationShort.Dansk, label: 'countries.da' },
  { value: LocationShort.Australia, label: 'countries.au' },
  { value: LocationShort.Spain, label: 'countries.es' },
  { value: LocationShort.Portugues, label: 'countries.pt' },
  { value: LocationShort.Norway, label: 'countries.no' },
  { value: LocationShort.Argentinia, label: 'countries.ar' },
  { value: LocationShort.Belgian, label: 'countries.be' },
  { value: LocationShort.Brazil, label: 'countries.br' },
  { value: LocationShort.Chile, label: 'countries.cl' },
  { value: LocationShort.CostaRica, label: 'countries.cr' },
  { value: LocationShort.Ecuador, label: 'countries.ec' },
  { value: LocationShort.Finland, label: 'countries.fi' },
  { value: LocationShort.France, label: 'countries.fr' },
  { value: LocationShort.Ireland, label: 'countries.ie' },
  { value: LocationShort.Italian, label: 'countries.it' },
  { value: LocationShort.Kolumbien, label: 'countries.co' },
  { value: LocationShort.Liechtenstein, label: 'countries.li' },
  { value: LocationShort.Luxemburg, label: 'countries.lu' },
  { value: LocationShort.Mexiko, label: 'countries.mx' },
  { value: LocationShort.Niederlande, label: 'countries.nl' },
  { value: LocationShort.New_Zealand, label: 'languages.nz' },
  { value: LocationShort.Österreich, label: 'countries.at' },
  { value: LocationShort.Paraguay, label: 'countries.py' },
  { value: LocationShort.Peru, label: 'countries.pe' },
  { value: LocationShort.Polen, label: 'countries.pl' },
  { value: LocationShort.Schweden, label: 'countries.se' },
  { value: LocationShort.Schweiz, label: 'countries.ch' },
  { value: LocationShort.Türkei, label: 'countries.tr' },
  { value: LocationShort.Uruguay, label: 'countries.uy' },
  { value: LocationShort.Venezuela, label: 'countries.ve' }
];

export const genderOptions = [
  { value: Gender.female, label: 'female' },
  { value: Gender.male, label: 'male' }
];

export const legitimationOptions = [
  { value: Legitimation.personal, label: 'passport' },
  { value: Legitimation.travel, label: 'passportTravel' }
];

const roleIDs = ['23', '9', '27', '10', '14', '16', '22'];

export const roleOptions = (ownId = '22') =>
  slice(roleIDs, indexOf(roleIDs, ownId)).map(item => {
    return { value: item, label: item };
  });

export type Permissions = {
  can_list_agency?: boolean;
  can_list_team?: boolean;
  can_list_agent?: boolean;
  can_list_quality_controle_ticket_center?: boolean;
  can_list_quality_controle_live_dialogs?: boolean;
  can_list_quality_controle_restroom?: boolean;
  can_list_quality_controle_freezed_dialogs?: boolean;
  can_list_statistics_time_management?: boolean;
  can_list_statistics_effectiveness?: boolean;
  can_list_statistics_in_out?: boolean;
  can_list_statistics_income?: boolean;
  can_list_billing?: boolean;
  can_create_agency?: boolean;
  can_update_agency?: boolean;
  can_create_team?: boolean;
  can_create_agent?: boolean;
  can_show_agent?: boolean;
  canShowStatisticsEffectiveness?: boolean;
  canShowStatisticsInOut?: boolean;
  canShowStatisticsTimeManagement?: boolean;
  canShowStatisticsIncome?: boolean;
  can_suspend_teams?: boolean;
  can_verify_agents?: boolean;
  can_admin_lock?: boolean;
  can_reset_admin_locked?: boolean;
  can_update_agent?: boolean;
  can_update_team?: boolean;
  can_suspend_agent?: boolean;
  can_list_agent_logs?: boolean;
  can_show_agent_logs?: boolean;
  can_update_agent_password?: boolean;
};