import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo
} from 'react';
import styled from 'styled-components';
import { useSpring, animated, useSprings } from 'react-spring';
import Icon from './Icon';

type ImageProps = {
  id: string;
  url: string;
};
const GalleryOverlayContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  opacity: 1;
  /* z-index: 10; */
  position: relative;
  @media only screen and (max-width: 415px) {
    height: 100%;
    padding: 5px;
  }
`;

const CenterImageContainer = styled.div`
  width: 500px;
  height: 500px;
  margin-top: 10px;
  margin-bottom: 22px;
  position: relative;
  max-width: 100%;
  border-radius: 10px;
  overflow: hidden;

  @media only screen and (max-height: 900px) {
    height: 400px;
    width: 400px;
  }

  @media only screen and (max-height: 800px) {
    height: 300px;
    width: 350px;
  }
`;

const Image = styled(animated.img)`
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  /* user-select: none; */
`;
const IconCircle = styled.div`
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 2px;
  cursor: pointer;
`;

function mod(n: number, m: number) {
  return ((n % m) + m) % m;
}

function getStyleFromIndex(
  index: number,
  shownIndex: number,
  width: number,
  length: number
) {
  // mid Pic
  if (index === shownIndex) {
    return {
      display: 'block',
      x: 0 * width,
      opacity: 1
    };
  }
  // left Pic
  if (shownIndex - index === 1 || (shownIndex === 0 && index === length - 1)) {
    return {
      display: 'block',
      x: -1 * width,
      opacity: 0
    };
  }
  // right Pic
  if (index - shownIndex === 1 || (shownIndex === length - 1 && index === 0)) {
    return {
      display: 'block',
      x: 1 * width,
      opacity: 0
    };
  }

  return {
    display: 'none',
    x: (index - shownIndex) * width,
    opacity: 0
  };
}

const PictureCarousel = ({ images }: { images: ImageProps[] }) => {
  const isSingle = useMemo(() => images?.length <= 1, [images.length]);
  const isDouble = useMemo(() => images?.length === 2, [images?.length]);
  const showedImages = useMemo(
    () => (isDouble ? [...images, ...images] : images),
    [isDouble, images]
  );
  const indexRef = useRef(0);
  const imageWidth = 500;
  const [imageSprings, set] = useSprings<{
    display: string;
    opacity: number;
    x: number;
  }>(showedImages.length, index => ({
    x: (index - indexRef.current) * (imageWidth || 1000),
    display: 'block',
    opacity: 1
  }));

  useEffect(() => {
    // @ts-ignore
    set((i: number) => {
      return getStyleFromIndex(
        i,
        indexRef.current,
        imageWidth,
        showedImages.length
      );
    });
  }, [imageWidth, set, showedImages.length]);

  const move = useCallback(
    right => {
      const newIndex = mod(
        indexRef.current + (right ? 1 : -1),
        showedImages.length
      );
      indexRef.current = newIndex;
      // @ts-ignore
      set((i: number) => {
        return getStyleFromIndex(i, newIndex, imageWidth, showedImages.length);
      });
    },
    [imageWidth, showedImages.length, set]
  );

  return (
    <GalleryOverlayContainer>
      {!isSingle && (
        <IconCircle onClick={() => move(false)}>
          <Icon name="chevron-left" size="25px" color="var(--color-fontOnBackground)" />
        </IconCircle>
      )}
      <CenterImageContainer>
        {imageSprings.map(({ x, display, opacity }, index) => {
          return (
            <Image
              style={{
                display,
                opacity,
                transform: x.interpolate(xS => `translateX(${xS}px)`)
              }}
              draggable={false}
              key={showedImages[index].id}
              src={showedImages[index].url}
            />
          );
        })}
      </CenterImageContainer>
      {!isSingle && (
        <IconCircle onClick={() => move(true)}>
          <Icon name="chevron-right" color="var(--color-fontOnBackground)" size="25px" />
        </IconCircle>
      )}
    </GalleryOverlayContainer>
  );
};

export default PictureCarousel;
