import { createGlobalStyle } from 'styled-components';
import StyleGuide from './StyleGuide';
/**
 * GlobalStyle
 */
const GlobalStyle = createGlobalStyle`
@font-face{
              font-family: emoji;
              src: 
                local("Apple Color Emoji"),
                local("Noto Color Emoji"),
                local("Android Emoji"),
                local("Segoe UI Emoji"),
                local("EmojiSymbols");
              unicode-range:
                U+1f300-1f5ff, /* misc symbols + pictographs */
                U+1f910-1f918, U+1f980-1f984, /* supplemental */
                U+1f600-1f64f, /* emoticons */
                U+1f680-1f6ff, /* transport */
                U+26??, /* misc */
                U+2700-27bf; /* dingbats */
            }
html {
  height: 100%
}
  body {
    margin: 0;
    padding: 0;
    font-family: emoji, Montserrat, Arial, Helvetica, sans-serif;
    font-weight: 300;
    height: 100%;
    background: ${StyleGuide.colors.background};  
    font-variant-numeric: tabular-nums lining-nums;
    overflow-x: hidden;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      width: 0px;
    }
  }
  #__next{
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: auto;
    scrollbar-width: none;
    ::-webkit-scrollbar {
    width: 0px;
    }
  }
  p, td {
    font-family: emoji, Montserrat, Arial, Helvetica, sans-serif;    
    font-variant-numeric: tabular-nums lining-nums;
  }
  :focus {
    outline: none;
  }
  input:-webkit-autofill,
 input:-webkit-autofill:hover,
 input:-webkit-autofill:focus,
 input:-webkit-autofill:active {
 -webkit-box-shadow: 0 0 0px 1000px ${StyleGuide.colors.loginInput} inset !important;
    -webkit-text-fill-color: ${StyleGuide.colors.fontOnPrimary} !important;
  }
  .react-datepicker {
    background-color: var(--color-tableRow);
  }
  
  .react-datepicker__header {
    background-color: var(--color-tableRowOdd);
  }

  .react-datepicker__current-month, .react-datepicker__day, .react-datepicker__day-name {
    color: var(--color-fontOnBackground);
  }
  /* .RichTextEditor__root{
    background-color: var(--color-tableRowOdd);
    color: var(--color-fontOnBackground);
  } */

`;

export default GlobalStyle;
