import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import StyleGuide from './StyleGuide';
import Modal from './Modal';
import { ModalText } from './ThemedComponents';

const ModalBox = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;
  height: inherit;
  justify-content: flex-start;
  align-items: stretch;
  background: ${StyleGuide.colors.loginBackground};
  overflow: hidden;
`;

const ModalHeader = styled.div`
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  background: ${StyleGuide.colors.loginBackground};
  ${StyleGuide.boxShadowLow};
  padding: 5px 20px;
  border-bottom: 1px solid;
  border-bottom-color: ${StyleGuide.colors.modalFontButton};
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  /* margin: 0 auto; */
`;

const PDF = styled.embed`
  height: 100%;
  width: 100%;
`;

const Button = styled.div<{ isActive?: boolean; isFocused?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 150px;
  height: 25px;
  margin: 5px;
  padding: 10px 5px;
  cursor: pointer;
  background: ${props =>
    props.isFocused
      ? StyleGuide.colors.modalFontButton
      : StyleGuide.colors.loginBackground};
  color: ${props =>
    props.isFocused
      ? StyleGuide.colors.modalFontButtonInActive
      : StyleGuide.colors.modalFontButton};
  border: ${props => (props.isFocused ? 'none' : '1px solid')};
  border-color: white;
`;

type ButtonProps = {
  text: string;
  isFocused?: boolean;
  action: () => void;
};

type CreateModalProps = {
  isVisible: boolean;
  setIsVisible: () => void;
  uri?: string;
  buttons?: Array<ButtonProps>;
  isPDF?: boolean;
};

const ImageOverlay: FunctionComponent<CreateModalProps> = ({
  isVisible = false,
  setIsVisible,
  uri = 'static/images/frankfurt.png',
  isPDF = false,
  buttons = [
    {
      text: 'Abbrechen',
      isFocused: false,
      action: setIsVisible
    },
    {
      text: 'Bestätigen',
      isFocused: true,
      action: setIsVisible
    }
  ]
}) => {
  return (
    <Modal isVisible={isVisible} isFilled={1}>
      <ModalBox>
        <ModalHeader>
          {buttons.map(item => (
            <Button
              key={item.text}
              isFocused={item.isFocused}
              onClick={item.action}
            >
              <ModalText>{item.text.toUpperCase()}</ModalText>
            </Button>
          ))}
        </ModalHeader>
        {isPDF ? <PDF src={uri} /> : <Image src={uri} alt="picture" />}
      </ModalBox>
    </Modal>
  );
};

export default ImageOverlay;
