export const colorModeKey = 'color-mode';
export const initialColorModeCSSProp = '--initial-color-mode';

export const COLORS = {
    header: {
        light: '#FFFFFF',
        dark: '#2E2F30'
    },
    background: {
        light: '#F0F0F0',
        dark: '#1F1F1F'
    },
    fontOnBackground: {
        light: '#000000',
        dark: '#FFFFFF'
    },
    surface: {
        light: '#FFFFFF',
        dark: '#2E2F30'
    },
    tableRow: {
        light: '#FFFFFF',
        dark: '#2E2F30'
    },
    tableRowOdd: {
        light: '#F9F9F9',
        dark: '#363636'
    },
    tableRowHover: {
        light: '#DDDDDD',
        dark: '#696969'
    },
    fontOnTableRow: {
        light: '#606266',
        dark: '#DDDDDD'
    },
    iconOnTableRow: {
        light: '#606266',
        dark: '#D6D6D6'
    },
    blend: {
        light: 'screen',
        dark: 'darken'
    },
    loader:{
        light: '#000000',
        dark: '#D6D6D6'
    },
    loaderLight: {
        light: '#FFFFFF',
        dark: '#4C4D4F'
    },
    loaderDark: {
        light: '#F5F5F5',
        dark: '#363738'
    },
    modalBGButtonInActive: {
        light: '#D3D3D3',
        dark: '#D3D3D3'
    },
    inputBorder: {
        light: '#e6e6e6',
        dark: '#696969'
    },
    themeIcon: {
        light: '#000000',
        dark: '#6CB6B6'
    }
};

const getInitialTheme = () => {

    const persistedColorPreference = window?.localStorage?.getItem('color-mode');
    const hasPersistedPreference = typeof persistedColorPreference === 'string';

    if (hasPersistedPreference)
        return persistedColorPreference;

    const mql = window?.matchMedia('prefers-color-scheme: dark');
    const hasMediaQueryPreference = typeof mql?.matches === 'boolean';

    if (hasMediaQueryPreference)
        return mql?.matches ? 'dark' : 'light';

    return 'light';

};

export const toggleTheme = () => {

    const colorMode = getInitialTheme() === 'light' ? 'dark' : 'light';

    const root = document?.documentElement;

    Object.entries(COLORS).forEach(([name, colorByTheme]: [string, { light: string, dark: string }]) => {
        const cssVarName = `--color-${name}`;
        // @ts-ignore
        root?.style.setProperty(cssVarName, colorByTheme[colorMode]);
    });

    root?.style?.setProperty('--initial-color-mode', colorMode);
    window?.localStorage?.setItem('color-mode', colorMode);

};

const light = {
    header: '#FFFFFF',
    fontOnHeader: '#000000',
    fontOnHeaderActive: '#6CB6B6',
    fontOnHeaderHover: '#6CB6B6',
    background: '#F0F0F0',
    surface: '#FFFFFF',
    fontOnSurface: '#000000',
    // Tables
    tableRow: '#FFFFFF',
    tableRowOdd: '#F9F9F9',
    tableRowError: '#FFBABA',
    tableRowHover: '#DDDDDD',
    fontOnTableRow: '#606266',
    fontOnTableRowError: '#D8000C',
    tableInfoBubble: '#B0B0B0',
    tableToolTip: '#A0A0A0',
    // General
    primary: '#6CB6B6',
    fontOnPrimary: '#FFFFFF',
    tooltipBackground: '#6D6D6D',
    error: '#FFBABA',
    fontOnError: '#D8000C',
    // Modal
    modalBackground: 'rgba(0,0,0,0.6)',
    modalFont: '#fff',
    modalFontButton: '#6CB6B6',
    modalFontButtonInActive: '#FFFFFF',
    modalBGButton: '#FFFFFF',
    modalBGButtonInActive: '#D3D3D3',
    // Input
    inputBorder: '#e6e6e6',
    inputPlaceHolder: '#b0b0b0',
    dragOverlay: 'rgba(108, 182, 182, 0.2)',
    inputError: '#D0021B',
    blendBackground: '#b0b0b0',
    // LoadingImageColors
    loader: '#000',
    loaderLight: '#FFFFFF',
    loaderDark: '#F5F5F5'
};

const dark = {
    header: '#2E2F30',
    fontOnHeader: '#FFFFFF',
    fontOnHeaderActive: '#6CB6B6',
    fontOnHeaderHover: '#6CB6B6',
    background: '#000000',
    surface: '#2E2F30',
    fontOnSurface: '#fff',
    // Tables
    tableRow: '#2E2F30',
    tableRowOdd: '#6D6D6D',
    tableRowError: '#FFBABA',
    tableRowHover: '#DDDDDD',
    fontOnTableRow: '#fff',
    // fontOnTableRow: '#606266',
    fontOnTableRowError: '#D8000C',
    tableInfoBubble: '#B0B0B0',
    tableToolTip: '#A0A0A0',
    // General
    primary: '#6CB6B6',
    fontOnPrimary: '#000000',
    tooltipBackground: '#6D6D6D',
    error: '#FFBABA',
    fontOnError: '#D8000C',
    // Modal
    modalBackground: 'rgba(0,0,0,0.7)',
    modalFont: '#FFFFFF',
    modalFontButton: '#6CB6B6',
    modalFontButtonInActive: '#fff',
    modalBGButton: '#2E2F30',
    modalBGButtonInActive: '#2E2F30',
    // Input
    inputBorder: '#e6e6e6',
    inputPlaceHolder: '#b0b0b0',
    dragOverlay: 'rgba(108, 182, 182, 0.2)',
    inputError: '#D0021B',
    blendBackground: '#2E2F30',
    // LoadingImageColors
    loader: '#b0b0b0',
    loaderLight: '#4C4D4F',
    loaderDark: '#363738',

    //////////////////////////////////////////////////////

    main: '#507FFF',
    mainVariantLight: '#66ACFF',
    mainVariantDark: '#0054B7',
    mainVariantUltraLight: '#D1E7F9',
    backgroundPrimary: '#2E2F30',
    backgroundSecondary: '#363738',
    backgroundFooterLight: '#363738',
    backgroundFooterDark: '#2E2F30',
    fontOnPrimaryy: '#fff',
    fontOnPrimaryLight: '#fff',
    fontOnSecondary: '#fff',
    fontOnMain: '#fff',
    backgroundd: 'linear-gradient(180deg, #3ABAFF 0%, #1B84FF 100%)',
    noBackground:
        'linear-gradient(180deg, #2E2F30 0%, #2E2F30 100%)',
    noBackgroundSecondary:
        'linear-gradient(180deg, #363738 0%, #363738 100%)',
    marker: '#D82D2D',
    markerText: '#fff',
    input: '#4C4D4F',
    inputFont: '#fff',
    inputIcon: '#507FFF',
    inputDark: '#4C4D4F',
    icon: '#507FFF',
    loaderLightt: '#4C4D4F',
    loaderDarkt: '#363738',
    splitter: '#4C4D4F',
    chatInfo: '#4C4D4F',
    chatInfoText: '#fff',
    tileFontPrimary: '#fff',
    tileFontSecondary: '#D3D3D3',
    blockOverlay: '#4C4D4F',
    blockOverlayFont: '#fff',
    paymentOption: 'linear-gradient(180deg, #4C4D4F 0%, #4C4D4F 100%)',
    externalLink: '#507FFF'
};

export default { light, dark };
