import React, { useEffect, FunctionComponent } from 'react';
/**
 * Simple Component setting the Title
 */

const Title: FunctionComponent<{ text: string }> = ({ text }) => {
    useEffect(() => {
        document.title = text;
    }, [text]);
    return null;
}

export default Title;