export enum LocationShort {
  German = 'de',
  English = 'uk',
  Norway = 'no',
  Australia = 'au',
  Spain = 'es',
  Portugues = 'pt',
  Dansk = 'dk',
  Argentinia = 'ar',
  Belgian = 'be',
  Brazil = 'br',
  Chile = 'cl',
  CostaRica = 'cr',
  Ecuador = 'ec',
  Finland = 'fi',
  France = 'fr',
  Ireland = 'ie',
  Italian = 'it',
  Kolumbien = 'co',
  Liechtenstein = 'li',
  Luxemburg = 'lu',
  Mexiko = 'mx',
  Niederlande = 'nl',
  Österreich = 'at',
  Paraguay = 'py',
  Peru = 'pe',
  Polen = 'pl',
  Schweden = 'se',
  Schweiz = 'ch',
  Türkei = 'tr',
  Uruguay = 'uy',
  Venezuela = 've',
  New_Zealand = 'nz',
}

export enum Gender {
  male = 1,
  female = 2
}

export enum Legitimation {
  personal = 'identity_card',
  travel = 'passport'
}

export const UserRole = {
  '14': 'teamleiter',
  '16': 'teamhelfer',
  '10': 'agenturhelfer',
  '22': 'agent',
  '25': 'admin',
  '29': 'affiliate',
  '28': 'affiliatemanager',
  '27': 'agenturersatzleiter',
  '26': 'hilfsadmin',
  '30': 'mediacreator',
  '23': 'president',
  '24': 'superadmin',
  '9': 'agenturleiter'
};

export enum AgentStatus {
  active = 'active',
  inactive = 'inactive',
  pending = 'pending',
  locked = 'locked'
}

export const getAgentsStatusEnum = (val: string): AgentStatus => {
  switch (val) {
    case 'active':
      return AgentStatus.active;
    case 'pending':
      return AgentStatus.pending;
    case 'locked':
      return AgentStatus.locked;
    default:
      return AgentStatus.inactive;
  }
};

export enum Status {
  Online = 'active',
  Offline = 'offline',
  Afk = 'afk'
}

export enum OperatingSystem {
  windows = 'Windows',
  iOs = 'iOS',
  linux = 'Linux',
  android = 'Android',
  macOs = 'macOS',
  other = 'Other'
}

export enum Browser {
  mozilla = 'Mozilla',
  chrome = 'Chrome',
  edge = 'Edge',
  safari = 'Safari',
  other = 'Other'
}

export enum HistoryEntryType {
  lock,
  unlock,
  ticket
}