import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import StyleGuide from './StyleGuide';
import Icon from './Icon';

const ActionButton = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  background: ${StyleGuide.colors.primary};
  position: absolute;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 100;
  top: -20px;
  left: -10px;
  ${StyleGuide.boxShadowHigh}
`;
type AddButtonProps = {
  onClick: () => void;
  visible: boolean;
};

const AddButton: React.FC<AddButtonProps> = ({ onClick, visible }) => {
  const [visibility, setVisibility] = useState(false);

  useEffect(() => setVisibility(visible), [visible]);
  return (
    <ActionButton onClick={onClick} visible={visibility}>
      <Icon
        name="add"
        size="20px"
        margin="10px"
        color={StyleGuide.colors.fontOnPrimary}
      />
    </ActionButton>
  );
}
export default AddButton;
