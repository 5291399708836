import styled from 'styled-components';
import StyleGuide from './StyleGuide';

const TableText = styled.p`
  color: var(--color-fontOnBackground);
`;

const TableTitle = styled.h2`
  color: var(--color-fontOnBackground);
`;

interface HeaderTextProps {
  isActive?: boolean;
  hovered?: boolean;
}
const HeaderText = styled.h1`
  font-size: 0.8em;
  text-align: center;
  font-weight: 400;
  margin: 5px;
  color: var(--color-fontOnBackground);
  text-transform: uppercase;
`;

type ModalTextProps = {
  color?: string;
  bold?: boolean;
  align?: string;
};

const ModalText = styled.p<ModalTextProps>`
  font-size: 0.9em;
  margin: 5px;
  font-weight: ${props => (props.bold ? '600' : '300')};
  text-align: ${props => props.align || 'center'};
  color:inherit;
  /* color: ${props => props.color || StyleGuide.colors.fontOnSurface}; */
`;
interface StatusProps {
  size?: string;
  color: string;
  margin?: string;
}
const Status = styled.div<StatusProps>`
  flex: 0 0 auto;
  width: ${props => props.size || '6px'};
  height: ${props => props.size || '6px'};
  background: ${props => props.color};
  margin: ${props => props.margin || '4px'};
  border-radius: 50%;
  overflow: hidden;
`;

const DimBackground = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
`;

export { HeaderText, TableText, TableTitle, DimBackground, ModalText, Status };
